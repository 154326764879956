import React from "react";
import styled from "@emotion/styled";
import { Skill } from "@/generated/graphql";
import { ProgressBar } from "@/features/common";
import { EvaluationPendingTag } from "./evaluation-pending-tag";

interface SkillScoreProps {
  skill: Skill;
  threshold: number;
}

const round = (value: number | null) =>
  !value || value <= 0 ? 0 : Math.floor(value);

export function SkillScore({ skill, threshold }: SkillScoreProps) {
  const roundedScore = round(skill.score);
  return (
    <div>
      <Description>
        <span data-recording-sensitive>{skill.name}</span>
        {skill.evaluated ? ` ${roundedScore}%` : <EvaluationPendingTag />}
      </Description>
      <ProgressBar
        value={skill.evaluated ? roundedScore : 100}
        color={
          skill.evaluated
            ? roundedScore < (threshold || 60)
              ? "red-100"
              : "green-100"
            : "purple-100"
        }
      />
    </div>
  );
}

const Description = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => props.theme.colors.purple[60]};
  margin-bottom: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
`;
