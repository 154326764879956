import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";
import { ApplicationState, useApplicationQuery } from "@/generated/graphql";
import { useApiErrorHandler } from "@/features/error";
import { Loader } from "@/features/common";
import {
  ApplicationDetailsCandidate,
  ApplicationDetailsTestList,
} from "@/features/application";

interface ApplicationDetailsProps {
  slug: string;
}

export function ApplicationDetails({ slug }: ApplicationDetailsProps) {
  const handleError = useApiErrorHandler();
  const [{ data, fetching, error }] = useApplicationQuery({
    variables: { slug },
  });

  useEffect(() => {
    if (!error) return;
    handleError(error);
  }, [handleError, error]);

  return (
    <ApplicationDetailsContainer>
      {fetching && <Loader absolute />}
      {data?.application && (
        <>
          {data.application.state !== ApplicationState.Invited && (
            <ApplicationDetailsCandidate
              applicationSlug={data.application.slug}
              applicationState={data.application.state}
              customFields={data.application.customFields}
              documents={data.application.documents}
              contactInfo={data.application.contactInfo}
            />
          )}
          <ApplicationDetailsTestList
            tests={data.application.tests}
            jobTitle={data.application.title}
            workspaceTitle={data.application.workspace.name}
            applicationSlug={data.application.slug}
            applicationState={data.application.state}
            recruiterContactEmail={data.application.recruiterContactEmail}
          />
        </>
      )}
    </ApplicationDetailsContainer>
  );
}

const ApplicationDetailsContainer = styled.div`
  background: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.shadow.card};
  border-radius: 8px;
  padding: 36px;
  display: grid;
  gap: 24px;
  position: relative;
  min-height: 104px;
  overflow-x: auto;

  @media ${device.tablet} {
    box-shadow: none;
    border-radius: unset;
    padding: 24px 10px;
  }
`;
