import React from "react";
import styled from "@emotion/styled";
import { Loader } from "@/features/common";
import {
  ApplicationListItem,
  TApplicationListItem,
} from "@/features/application";
import { EmptyList } from "./ui";

interface ApplicationListProps {
  applications: TApplicationListItem[] | null;
  loading?: boolean;
}

export function ApplicationList({
  applications,
  loading,
}: ApplicationListProps) {
  return (
    <>
      {!loading && !applications?.length ? (
        <EmptyList />
      ) : (
        <ApplicationListContainer>
          {loading && <Loader absolute />}
          {applications?.map(
            (item) =>
              !!item && (
                <ApplicationListItem key={item.slug} application={item} />
              )
          )}
        </ApplicationListContainer>
      )}
    </>
  );
}

const ApplicationListContainer = styled.div`
  display: grid;
  gap: 16px;
  position: relative;
  min-height: 104px;
`;
