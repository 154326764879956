import React from "react";
import { addSeconds } from "date-fns";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";
import {
  ApplicationState,
  Skill,
  Test,
  TestState,
  TestType,
} from "@/generated/graphql";
import { formatTimestamp } from "@/features/common";
import {
  getTestTypeName,
  TestActionButton,
  TestScoreBreakdown,
} from "@/features/test";

interface TestDetailsProps {
  test: Test;
  jobTitle: string;
  workspaceTitle: string | null;
  firstInvite: boolean;
  applicationSlug: string;
  applicationState: ApplicationState;
  recruiterContactEmail: string;
}

export function TestDetails({
  test,
  jobTitle,
  workspaceTitle,
  firstInvite,
  applicationSlug,
  applicationState,
  recruiterContactEmail,
}: TestDetailsProps) {
  if (
    applicationState !== ApplicationState.Open &&
    applicationState !== ApplicationState.Invited &&
    [TestState.Invited, TestState.Started].includes(test.state)
  )
    return (
      <Container>
        <Info>
          This test is no longer available. If you have any questions please
          contact {recruiterContactEmail}.
        </Info>
      </Container>
    );

  switch (test.state) {
    case TestState.Invited:
      const testTypeName = getTestTypeName(test.type);
      return (
        <Container>
          <Info>
            {firstInvite ? (
              <>
                New opportunity!{" "}
                <span data-recording-sensitive>{workspaceTitle}</span> is
                inviting you to take a{" "}
                <span data-recording-sensitive>{jobTitle}</span> {testTypeName}.
              </>
            ) : (
              <>
                Congrats! You made it to the next step. Keep up the momentum and
                complete the {testTypeName}.
              </>
            )}
          </Info>
          <Action>
            <TestActionButton
              testState={test.state}
              testType={test.type}
              testSlug={test.slug}
              applicationSlug={applicationSlug}
            />
          </Action>
        </Container>
      );

    case TestState.Started:
      const endTimestamp = test.stateTimeStamp
        ? addSeconds(
            new Date(test.stateTimeStamp),
            test.timeLimit
          ).toISOString()
        : null;
      const formatedEndTimestamp = formatTimestamp(endTimestamp);
      return (
        <Container>
          <Info>
            You have started the test. Complete it by {formatedEndTimestamp}.
          </Info>
          <Action>
            <TestActionButton
              testState={test.state}
              testType={test.type}
              testSlug={test.slug}
              applicationSlug={applicationSlug}
            />
          </Action>
        </Container>
      );

    case TestState.Completed:
      if (test.type === TestType.Quiz) {
        return (
          <TestScoreBreakdown
            score={test.score}
            threshold={test.threshold}
            skills={test.skills as Skill[]}
          />
        );
      } else {
        const testTypeName = getTestTypeName(test.type);
        return (
          <Container>
            <Info>
              Great job! You have already completed {testTypeName} and{" "}
              <span data-recording-sensitive>{workspaceTitle}</span> has
              received it.
            </Info>
          </Container>
        );
      }

    case TestState.OutOfTime:
      const formatedStateTimestamp = formatTimestamp(test.stateTimeStamp);
      return (
        <Container>
          <Info>
            Unfortunately you have ran out of time
            {!!formatedStateTimestamp && ` on ${formatedStateTimestamp}`}. If
            you have any questions please contact {recruiterContactEmail}.
          </Info>
        </Container>
      );

    default:
      return null;
  }
}

const Container = styled.div`
  padding: 36px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media ${device.tablet} {
    padding: 16px;
    flex-direction: column;
    align-items: unset;
    gap: 30px;
  }
`;

const Info = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.typography.colorPrimary};
`;

const Action = styled.span`
  display: flex;
  justify-content: flex-end;
`;
