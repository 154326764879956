import React from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";
import { Test, TestState, TestType } from "@/generated/graphql";
import {
  getTestStateInfo,
  getTestTypeName,
  TestScoreTag,
} from "@/features/test";

interface TestHeaderProps {
  test: Test;
}
export function TestHeader({ test }: TestHeaderProps) {
  return (
    <Container>
      <Title>{getTestTypeName(test.type)}</Title>

      {test.state === TestState.Completed && test.type === TestType.Quiz && (
        <Score>
          <TestScoreTag score={test.score} threshold={test.threshold} />
        </Score>
      )}

      <Description>
        {getTestStateInfo(test.state, test.stateTimeStamp)}
      </Description>
    </Container>
  );
}

const Container = styled.div`
  background: ${(props) => props.theme.colors.purple[5]};
  border-radius: 8px;
  padding: 6px 16px;
  display: flex;
  align-items: center;
  gap: 4px;

  @media ${device.tablet} {
    padding: 4px 16px;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 14px;
  white-space: nowrap;
  color: ${(props) => props.theme.typography.colorPrimary};
`;

const Score = styled.div`
  display: none;

  @media ${device.tablet} {
    display: inline-flex;
  }
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  margin-left: auto;
  text-align: end;
  color: ${(props) => props.theme.typography.colorSecondary};
`;
