import React from "react";
import styled from "@emotion/styled";
import { TestState, TestType } from "@/generated/graphql";
import { getTestStateLabel, TestScoreTag } from "@/features/test";

interface TestStateTagProps {
  testState: TestState;
  testType: TestType;
  score: number | null;
  threshold: number;
}

export function TestStateTag({
  testState,
  testType,
  score,
  threshold,
}: TestStateTagProps) {
  switch (testState) {
    case TestState.Invited:
    case TestState.Started:
    case TestState.OutOfTime:
      return <State state={testState}>{getTestStateLabel(testState)}</State>;

    case TestState.Completed:
      return testType === TestType.Quiz ? (
        <TestScoreTag score={score} threshold={threshold} />
      ) : (
        <State state={testState}>{getTestStateLabel(testState)}</State>
      );
  }
}

const State = styled.div<{ state: TestState }>`
  font-size: 10px;
  font-weight: 600;
  white-space: nowrap;
  padding: 0 8px;
  border-radius: 8px;
  height: 16px;
  display: inline-flex;
  color: ${(props) => props.theme.typography.colorPrimary};

  background: ${(props) => {
    switch (props.state) {
      case TestState.Invited:
      case TestState.Started:
        return props.theme.colors.yellow[50];

      case TestState.Completed:
        return props.theme.colors.green[10];

      case TestState.OutOfTime:
        return props.theme.colors.red[10];

      default:
        return null;
    }
  }};
`;
