import React from "react";
import styled from "@emotion/styled";

interface TestScoreTagProps {
  score: number | null;
  threshold: number;
}

const round = (value: number | null) =>
  !value || value <= 0 ? 0 : Math.floor(value);

export function TestScoreTag({ score, threshold }: TestScoreTagProps) {
  const roundedScore = round(score);
  return (
    <Score passed={roundedScore >= (threshold || 60)}>{roundedScore}%</Score>
  );
}

const Score = styled.div<{ passed: boolean }>`
  font-size: 10px;
  font-weight: 600;
  white-space: nowrap;
  padding: 0 8px;
  border-radius: 8px;
  height: 16px;
  display: inline-flex;

  color: ${(props) =>
    props.passed ? props.theme.colors.green[100] : props.theme.colors.red[100]};

  background: ${(props) =>
    props.passed ? props.theme.colors.green[10] : props.theme.colors.red[10]};
`;
