import React from "react";
import styled from "@emotion/styled";
import { Test } from "@/generated/graphql";
import { getTestTypeName, TestStateTag } from "@/features/test";
import { Carousel, useCarousel } from "@/features/common";

interface ApplicationTestsProps {
  tests: Test[] | null;
}

export function ApplicationTests({ tests }: ApplicationTestsProps) {
  const {
    containerRef,
    contentRef,
    goNext,
    goPrev,
    canGoNext,
    canGoPrev,
    offset,
  } = useCarousel({ slideStep: 60 });

  return (
    <Wrapper>
      <Container ref={containerRef}>
        <Content style={offset} ref={contentRef}>
          {tests?.map((test) => (
            <TestBlock key={test.slug}>
              <TestTitle>{getTestTypeName(test.type)}</TestTitle>
              <TestStateTag
                testState={test.state}
                testType={test.type}
                score={test.score}
                threshold={test.threshold}
              />
            </TestBlock>
          ))}
        </Content>
      </Container>

      {(canGoPrev || canGoNext) && (
        <Carousel
          canGoToPrev={canGoPrev}
          canGoToNext={canGoNext}
          goPrev={goPrev}
          goNext={goNext}
          width="48px"
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  grid-area: tests;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`;

const Container = styled.div`
  overflow: hidden;
`;

const Content = styled.div`
  display: flex;
  position: relative;
  width: max-content;
  gap: 16px;
`;

const TestBlock = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const TestTitle = styled.div`
  font-weight: 500;
  font-size: 10px;
  color: ${(props) => props.theme.colors.purple[50]};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
`;
