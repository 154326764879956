import { Test, TestState } from "@/generated/graphql";

export function sortByStateAndDate(tests: Test[]) {
  const stateOrder = {
    [TestState.Invited]: 1,
    [TestState.Started]: 2,
    [TestState.OutOfTime]: 3,
    [TestState.Completed]: 4,
  };

  tests.sort((a, b) => {
    // compare states
    const stateComparison = stateOrder[a.state] - stateOrder[b.state];
    if (stateComparison !== 0) {
      return stateComparison;
    }
    // if states are the same, compare dates
    if (a.stateTimeStamp! > b.stateTimeStamp!) return -1;
    if (a.stateTimeStamp! < b.stateTimeStamp!) return 1;
    return 0;
  });

  return tests;
}
