import { TestState } from "@/generated/graphql";
import { formatDistanceToNow } from "@/features/common";

export const getTestStateLabel = (testState: TestState) => {
  switch (testState) {
    case TestState.Invited:
      return "Invited";

    case TestState.Started:
      return "Started";

    case TestState.Completed:
      return "Completed";

    case TestState.OutOfTime:
      return "Out of time";

    default:
      return testState;
  }
};

export const getTestStateInfo = (
  testState: TestState,
  testStateTimeStamp: string | null
) => {
  const distance = formatDistanceToNow(testStateTimeStamp, true);

  switch (testState) {
    case TestState.Invited:
      return `Invited ${distance}`;

    case TestState.Started:
      return `Started ${distance}`;

    case TestState.Completed:
      return `Completed ${distance}`;

    case TestState.OutOfTime:
      return "Ran out of time";

    default:
      return testState;
  }
};
