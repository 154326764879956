import { useMemo } from "react";
import {
  ApplicationState,
  ContactInfo,
  CustomField,
} from "@/generated/graphql";

export function useApplicationMissingCandidateDetails(
  applicationState: ApplicationState,
  customFields?: CustomField[] | null,
  contactInfo?: ContactInfo[] | null
) {
  return useMemo(
    () =>
      applicationState === ApplicationState.Open &&
      (!!customFields?.find((field) => field.mandatory && !field.value) ||
        !!contactInfo?.find((field) => field.mandatory && !field.value)),
    [applicationState, customFields, contactInfo]
  );
}
