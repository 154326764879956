import React from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";
import { Skill } from "@/generated/graphql";
import { SkillScore } from "./ui";
import { ProgressCircle } from "@/features/common";

interface TestScoreBreakdownProps {
  score: number | null;
  threshold: number;
  skills: Skill[];
}

const round = (value: number | null) =>
  !value || value <= 0 ? 0 : Math.floor(value);

export function TestScoreBreakdown({
  score,
  threshold,
  skills,
}: TestScoreBreakdownProps) {
  const roundedScore = round(score);
  return (
    <Container>
      <Score>
        <ProgressCircle
          value={roundedScore}
          color={roundedScore < (threshold || 60) ? "red-100" : "green-100"}
        />
      </Score>
      <Skills>
        {skills?.map((skill) => (
          <SkillScore key={skill.name} skill={skill} threshold={threshold} />
        ))}
      </Skills>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 36px;
  padding: 36px 0;
  align-items: center;

  @media ${device.tablet} {
    padding: 16px;
  }
`;

const Score = styled.div`
  @media ${device.tablet} {
    display: none;
  }
`;

const Skills = styled.div`
  flex-grow: 1;
  max-width: 55%;
  display: grid;
  gap: 16px;
  grid-auto-rows: max-content;

  @media ${device.tablet} {
    max-width: 100%;
  }
`;
