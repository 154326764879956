import React from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";

export function EmptyList() {
  return (
    <EmptyListContainer>You have no active applications</EmptyListContainer>
  );
}

const EmptyListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.typography.colorPrimary};
  background-color: ${(props) => props.theme.colors.purple[5]};
  height: 104px;
  border-radius: 8px;

  @media ${device.tablet} {
    font-size: 12px;
  }
`;
