import { TestType } from "@/generated/graphql";

export const getTestTypeName = (testType: TestType) => {
  switch (testType) {
    case TestType.Quiz:
      return "Skills Test";

    case TestType.Video:
      return "Video Intro";

    case TestType.Homework:
      return "Homework";

    case TestType.Interview:
      return "Interview";

    default:
      return testType;
  }
};
