import React from "react";
import styled from "@emotion/styled";

export function EvaluationPendingTag() {
  return <Tag>Answer received</Tag>;
}

const Tag = styled.div`
  font-size: 10px;
  font-weight: 600;
  white-space: nowrap;
  padding: 0 8px;
  border-radius: 8px;
  height: 16px;
  display: inline-flex;
  color: ${(props) => props.theme.typography.colorPrimary};
  background: ${(props) => props.theme.colors.green[10]};
`;
