import { string, object, array } from "yup";
import { Document, CustomField, ContactInfo } from "@/generated/graphql";
import { TCandidateDetailsForm } from "./application-details-candidate.types";

export function getInitialValues(
  contactInfo?: ContactInfo[] | null,
  customFields?: CustomField[] | null,
  documents?: Document[] | null
): TCandidateDetailsForm {
  return {
    fields:
      customFields?.map((field) => ({
        id: field.id,
        title: field.title,
        value: field.value || "",
        mandatory: field.mandatory,
        readonly: !!field.value,
      })) || [],
    files:
      documents?.map((document) => ({
        id: document.id,
        title: document.title,
        fileName: document.fileName || "",
        file: null,
        readonly: !!document.fileName,
      })) || [],
    info:
      contactInfo?.map((field) => ({
        id: field.id,
        key: field.key,
        title: field.title,
        value: field.value || "",
        mandatory: field.mandatory,
        readonly: !!field.value,
      })) || [],
  };
}

export function getValidationSchema() {
  return object({
    fields: array(
      object({
        value: string()
          .trim()
          .when("mandatory", ([mandatory], schema) => {
            return mandatory
              ? schema.required("This field is mandataory")
              : schema;
          }),
      })
    ),
    info: array(
      object({
        value: string()
          .trim()
          .when("mandatory", ([mandatory], schema) => {
            return mandatory
              ? schema.required("This field is mandataory")
              : schema;
          })
          .when("key", ([key], schema) => {
            return key === "linkedInUrl"
              ? schema.matches(
                  /^https:\/\/([a-z]{2,3}\.)?linkedin\.com/,
                  "Invalid URL format. Example: https://www.linkedin.com/in/user-name-123"
                )
              : schema;
          }),
      })
    ),
  });
}
