import React from "react";
import { useRouter } from "next/router";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";
import { DSButton } from "@hundred5/design-system";
import { Icon } from "@/features/common";
import { faBell } from "@fortawesome/pro-regular-svg-icons";

interface MissingDetailsProps {
  slug: string;
}

export function MissingDetails(props: MissingDetailsProps) {
  const router = useRouter();

  return (
    <Container>
      <span>
        <WarningIcon icon={faBell} color="red-100" />
        Some details are missing. Please{" "}
        <EditMissingDetailsLink
          onClick={() => router.push(`/application/${props.slug}`)}
        >
          add additional info
        </EditMissingDetailsLink>
        <EditMissingDetailsText>add additional info</EditMissingDetailsText>{" "}
        requested by the employer.
      </span>
      <EditMissingDetailsButton
        variant="secondary"
        size="small"
        onClick={() => router.push(`/application/${props.slug}`)}
      >
        Edit
      </EditMissingDetailsButton>
    </Container>
  );
}

const WarningIcon = styled(Icon)`
  margin-right: 4px;
  @media ${device.tablet} {
    display: none;
  }
`;

const EditMissingDetailsLink = styled.a`
  color: inherit;
  text-decoration: underline;

  @media ${device.tablet} {
    display: none;
  }
`;

const EditMissingDetailsText = styled.span`
  display: none;

  @media ${device.tablet} {
    display: inline;
  }
`;

const EditMissingDetailsButton = styled(DSButton)`
  align-self: center;
  display: none;

  @media ${device.tablet} {
    display: inline;
  }
`;

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr) auto;
  gap: 16px;
  background: ${(props) => props.theme.colors.orange[10]};
  padding: 20px 16px;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.typography.colorPrimary};
  align-items: center;

  @media ${device.tablet} {
    padding: 8px 16px;
    font-size: 12px;
  }
`;
