import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";
import { ApplicationState, Test, TestState } from "@/generated/graphql";
import { TestDetails, TestHeader } from "./ui";
import { sortByStateAndDate } from "./application-details-test-list.utils";

interface ApplicationDetailsTestListProps {
  tests: Test[] | null;
  jobTitle: string;
  workspaceTitle: string | null;
  applicationSlug: string;
  applicationState: ApplicationState;
  recruiterContactEmail: string;
}

export function ApplicationDetailsTestList({
  tests,
  jobTitle,
  workspaceTitle,
  applicationSlug,
  applicationState,
  recruiterContactEmail,
}: ApplicationDetailsTestListProps) {
  const sortedTests = useMemo(
    () => (tests ? sortByStateAndDate([...tests]) : []),
    [tests]
  );

  return (
    <ApplicationDetailsTestListContainer>
      <Title>Tests</Title>

      {sortedTests.map((test, i) => (
        <div key={test.slug}>
          <TestHeader test={test} />

          <TestDetails
            test={test}
            jobTitle={jobTitle}
            workspaceTitle={workspaceTitle}
            firstInvite={
              test.state === TestState.Invited && i === sortedTests.length - 1
            }
            applicationSlug={applicationSlug}
            applicationState={applicationState}
            recruiterContactEmail={recruiterContactEmail}
          />
        </div>
      ))}
    </ApplicationDetailsTestListContainer>
  );
}

const ApplicationDetailsTestListContainer = styled.div`
  display: grid;
  gap: 24px;

  @media ${device.tablet} {
    gap: 16px;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: ${(props) => props.theme.typography.colorPrimary};
`;
