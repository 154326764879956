import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";
import { useApplicationsActiveQuery } from "@/generated/graphql";
import { useApiErrorHandler } from "@/features/error";
import { ApplicationList, TApplicationListItem } from "@/features/application";

export function ApplicationsActive({}) {
  const handleError = useApiErrorHandler();
  const [{ data, fetching, error }] = useApplicationsActiveQuery();

  useEffect(() => {
    if (!error) return;
    handleError(error);
  }, [handleError, error]);

  return (
    <>
      <Title>Active Applications</Title>
      <Subtitle>
        See your application status and take the needed actions to move on to
        the next stage.
      </Subtitle>
      <ApplicationList
        applications={data?.applications as TApplicationListItem[]}
        loading={fetching}
      />
    </>
  );
}

const Title = styled.div`
  font-weight: 700;
  font-size: 22px;
  color: ${(props) => props.theme.typography.colorPrimary};
  margin-bottom: 8px;

  @media ${device.tablet} {
    font-size: 20px;
    margin-bottom: 16px;
  }
`;

const Subtitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.colors.purple[70]};
  margin-bottom: 16px;

  @media ${device.tablet} {
    display: none;
  }
`;
