import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Icon } from "@/features/common";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { useApplicationsPastQuery } from "@/generated/graphql";
import { useApiErrorHandler } from "@/features/error";
import { ApplicationList, TApplicationListItem } from "@/features/application";

export function ApplicationsPast({}) {
  const handleError = useApiErrorHandler();
  const [openPast, setOpenPast] = useState(false);
  const [{ data, error }] = useApplicationsPastQuery();

  useEffect(() => {
    if (!error) return;
    handleError(error);
  }, [handleError, error]);

  return !!data?.applications && data?.applications.length > 0 ? (
    <>
      <ExpandableTitle onClick={() => setOpenPast((prevState) => !prevState)}>
        Past Applications
        <ExpandingArrow
          open={openPast}
          icon={faAngleDown}
          color="purple-60"
          fontSize="16px"
        />
      </ExpandableTitle>

      {openPast && (
        <ApplicationList
          applications={data?.applications as TApplicationListItem[]}
        />
      )}
    </>
  ) : null;
}

const ExpandableTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.typography.colorPrimary};
  margin: 24px 0 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ExpandingArrow = styled(Icon)<{ open: boolean }>`
  transform: rotate(${(props) => (props.open ? 180 : 0)}deg);
  transition: transform 100ms ease-in-out;
`;
