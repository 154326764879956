import React, { useCallback } from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";

interface ApplicationTitleProps {
  jobTitle: string;
  workspaceTitle: string | null;
}

export function ApplicationTitle({
  jobTitle,
  workspaceTitle,
}: ApplicationTitleProps) {
  const [titleEllipsisActive, setTitleEllipsisActive] = React.useState(false);

  const handleTitleEllipsisActive = useCallback(
    (element: HTMLHeadingElement) => {
      setTitleEllipsisActive(
        !!element && element.offsetWidth < element.scrollWidth
      );
    },
    []
  );

  return (
    <Container
      ref={handleTitleEllipsisActive}
      {...(titleEllipsisActive && {
        "data-rh": `${jobTitle} - ${workspaceTitle}`,
        "data-rh-at": "top",
      })}
      data-recording-sensitive
    >
      {jobTitle}
      {!!workspaceTitle && <WorkspaceTitle>{workspaceTitle}</WorkspaceTitle>}
    </Container>
  );
}

const Container = styled.div`
  grid-area: title;
  font-weight: 700;
  font-size: 16px;
  color: ${(props) => props.theme.typography.colorPrimary};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  align-self: end;

  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    white-space: initial;
  }
`;

const WorkspaceTitle = styled.span`
  ::before {
    content: " - ";
    white-space: pre;
  }

  @media ${device.tablet} {
    ::before {
      content: none;
    }
  }
`;
