import React from "react";
import styled from "@emotion/styled";
import { ApplicationState, Test, TestState } from "@/generated/graphql";
import { formatDistanceToNow } from "@/features/common";
import { getTestTypeName } from "@/features/test";

interface InformationProps {
  applicationState: ApplicationState;
  applicationStateTimestamp: string | null;
  latestTest?: Test;
}

export function Information({
  applicationState,
  applicationStateTimestamp,
  latestTest,
}: InformationProps) {
  const testTypeName = !!latestTest && getTestTypeName(latestTest.type);
  const testStateTimestampDistance =
    !!latestTest?.stateTimeStamp &&
    formatDistanceToNow(latestTest.stateTimeStamp, true);

  if (applicationState === ApplicationState.Invited) {
    const applicationStateTimestampDistance = formatDistanceToNow(
      applicationStateTimestamp,
      true
    );
    return (
      <Container>
        Invited to {testTypeName} {applicationStateTimestampDistance}
      </Container>
    );
  }

  if (applicationState !== ApplicationState.Open) {
    const applicationStateTimestampDistance = formatDistanceToNow(
      applicationStateTimestamp,
      true
    );
    return (
      <Container>Job closed {applicationStateTimestampDistance}</Container>
    );
  }

  switch (latestTest?.state) {
    case TestState.Invited:
      return (
        <Container>
          Invited to {testTypeName} {testStateTimestampDistance}
        </Container>
      );

    case TestState.Started:
      return (
        <Container>
          {testTypeName} started {testStateTimestampDistance}
        </Container>
      );

    case TestState.Completed:
      return (
        <Container>
          {testTypeName} completed {testStateTimestampDistance}
        </Container>
      );

    case TestState.OutOfTime:
      return (
        <Container>
          {testTypeName} ran out of time {testStateTimestampDistance}
        </Container>
      );

    default:
      return null;
  }
}

const Container = styled.div`
  grid-area: info;
  align-self: start;
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  color: ${(props) => props.theme.typography.colorSecondary};
`;
