import React from "react";
import { DSButton } from "@hundred5/design-system";
import { TestState, TestType } from "@/generated/graphql";
import { useAmplitude } from "@/features/analytics";

interface TestActionButtonProps {
  testState: TestState;
  testType: TestType;
  testSlug: string;
  applicationSlug: string;
}

const getActionButtonLabel = (testType: TestType) => {
  switch (testType) {
    case TestType.Quiz:
      return "Take test";

    case TestType.Video:
      return "Record video";

    case TestType.Homework:
      return "Complete homework";

    case TestType.Interview:
      return "Complete interview";

    default:
      return null;
  }
};

export function TestActionButton({
  testState,
  testType,
  testSlug,
  applicationSlug,
}: TestActionButtonProps) {
  const { logEvent } = useAmplitude();

  switch (testState) {
    case TestState.Invited:
    case TestState.Started:
      return (
        <DSButton
          onClick={(e) => {
            e.stopPropagation();

            logEvent("application/take test", {
              "test type": testType,
              "test state": testState,
              "test slug": testSlug,
            });

            window.open(
              `${process.env.NEXT_PUBLIC_CANDIDATE_APP_URL}/${applicationSlug}/${testSlug}`,
              "_blank"
            );
          }}
        >
          {getActionButtonLabel(testType)}
        </DSButton>
      );

    case TestState.OutOfTime:
    case TestState.Completed:
    default:
      return null;
  }
}
