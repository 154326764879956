import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { device } from "@/styles/breakpoints";
import { Formik, Form } from "formik";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { DSButton } from "@hundred5/design-system";
import {
  ApplicationState,
  ContactInfo,
  ContactInfoInput,
  CustomField,
  CustomFieldInput,
  Document,
  useUpdateContactInfoMutation,
  useUpdateCustomFieldsMutation,
  useUploadDocumentMutation,
} from "@/generated/graphql";
import {
  FormikInput,
  FormikUpload,
  Icon,
  useFlashMessages,
} from "@/features/common";
import { useAmplitude } from "@/features/analytics";
import { useApiErrorHandler } from "@/features/error";
import { useApplicationMissingCandidateDetails } from "@/features/application";
import { TCandidateDetailsForm } from "./application-details-candidate.types";
import {
  getInitialValues,
  getValidationSchema,
} from "./application-details-candidate.utils";

interface ApplicationDetailsCandidateProps {
  applicationSlug: string;
  applicationState: ApplicationState;
  customFields: CustomField[] | null;
  documents: Document[] | null;
  contactInfo: ContactInfo[] | null;
}

export function ApplicationDetailsCandidate({
  applicationSlug,
  applicationState,
  documents,
  customFields,
  contactInfo,
}: ApplicationDetailsCandidateProps) {
  const handleError = useApiErrorHandler();
  const { addMessage } = useFlashMessages();
  const { logEvent } = useAmplitude();
  const [, updateContactInfo] = useUpdateContactInfoMutation();
  const [, updateCustomFields] = useUpdateCustomFieldsMutation();
  const [, uploadDocument] = useUploadDocumentMutation();

  const missingMandatoryDetails = useApplicationMissingCandidateDetails(
    applicationState,
    customFields,
    contactInfo
  );

  const hasUnfilledDetails =
    !!customFields?.find((field) => !field.value) ||
    !!contactInfo?.find((field) => !field.value) ||
    !!documents?.find((file) => !file.fileName);

  const handleSubmit = async (values: TCandidateDetailsForm) => {
    const contactInfoForSave = values.info.reduce(
      (result: { [key: string]: any }, item) => {
        result[item.key] = item.value;
        return result;
      },
      {}
    );

    const customFieldsForSave: CustomFieldInput[] = values.fields
      .filter((field) => !field.readonly && !!field.value)
      .map((field) => ({
        title: field.title,
        value: field.value,
      }));

    const documentsForSave: { file: File; id: string }[] = values.files
      .filter((file) => !!file.file)
      .map((file) => ({
        file: file.file!,
        id: file.id,
      }));

    const results = await Promise.allSettled([
      !!contactInfoForSave
        ? updateContactInfo({
            applicationSlug: applicationSlug,
            contactInfo: contactInfoForSave as ContactInfoInput,
          })
        : Promise.resolve(undefined),
      !!customFieldsForSave.length
        ? updateCustomFields({
            applicationSlug: applicationSlug,
            fields: customFieldsForSave,
          })
        : Promise.resolve(undefined),
      ...documentsForSave.map((document) => uploadDocument(document)),
    ]);

    const anyResultFailed = results.reduce((acc, result) => {
      if (result.status !== "fulfilled") {
        handleError(result.reason);
        return true;
      } else if (result.value?.error) {
        handleError(result.value.error);
        return true;
      }
      return acc;
    }, false);

    if (!anyResultFailed) {
      addMessage({ type: "save_success" });
      logEvent("application details/save candidate details");
    }
  };
  const applicationIsClosed = applicationState !== ApplicationState.Open;

  if (!contactInfo && !customFields && !documents) return null;

  return (
    <ApplicationDetailsCandidateContainer error={missingMandatoryDetails}>
      <Title>
        Your details
        <Icon
          icon={faCircleInfo}
          color="purple-60"
          fontSize="14px"
          data-rh-at="right"
          data-rh="Details requested by the employer"
        />
      </Title>
      <Subtitle>Details requested by the employer</Subtitle>

      <Formik
        onSubmit={handleSubmit}
        initialValues={getInitialValues(contactInfo, customFields, documents)}
        validationSchema={getValidationSchema()}
        enableReinitialize={true}
      >
        {({ dirty, isSubmitting, values }) => (
          <Form data-recording-ignore="mask">
            <Content>
              {values.info.map((field, indx) => (
                <FormikInput
                  key={field.id}
                  name={`info[${indx}].value`}
                  label={field.title}
                  required={field.mandatory}
                  disabled={field.readonly || applicationIsClosed}
                />
              ))}
              {values.fields.map((field, indx) => (
                <FormikInput
                  key={field.id}
                  name={`fields[${indx}].value`}
                  label={field.title}
                  required={field.mandatory}
                  disabled={field.readonly || applicationIsClosed}
                />
              ))}
            </Content>

            {!!values.files.length && (
              <>
                <SectionTitle>Attachments</SectionTitle>
                <Content>
                  {values.files.map((file, indx) => (
                    <FormikUpload
                      key={file.id}
                      name={`files[${indx}].file`}
                      label={file.title}
                      fileName={file.fileName}
                      disabled={file.readonly || applicationIsClosed}
                    />
                  ))}
                </Content>
              </>
            )}
            {hasUnfilledDetails && !applicationIsClosed && (
              <Footer>
                <DSButton type="submit" disabled={!dirty || isSubmitting}>
                  Save changes
                </DSButton>
              </Footer>
            )}
          </Form>
        )}
      </Formik>
    </ApplicationDetailsCandidateContainer>
  );
}

const ApplicationDetailsCandidateContainer = styled.div<{ error: boolean }>`
  position: relative;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.purple[10]};
  padding: 36px;

  ${(props) =>
    props.error &&
    css`
      :before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 4px;
        width: 100%;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background-color: ${props.theme.colors.red[100]};
      }
    `}

  @media ${device.tablet} {
    padding: 16px;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: ${(props) => props.theme.typography.colorPrimary};
  display: flex;
  gap: 4px;
  align-items: center;

  @media ${device.tablet} {
    display: none;
  }
`;

const Subtitle = styled.div`
  display: none;
  font-weight: 700;
  font-size: 14px;
  color: ${(props) => props.theme.typography.colorPrimary};

  @media ${device.tablet} {
    display: block;
  }
`;

const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: ${(props) => props.theme.typography.colorPrimary};
  padding-top: 24px;

  @media ${device.tablet} {
    font-size: 14px;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(200px, calc((100% - 12px) / 2)), 1fr)
  );
  gap: 12px;
  padding-top: 16px;
`;

const Footer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: end;
  align-items: center;
`;
