import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";
import { useApplicationHeaderQuery } from "@/generated/graphql";
import { useApiErrorHandler } from "@/features/error";
import { NavigationHeader } from "@/features/common";

interface ApplicationNavigationHeaderProps {
  slug: string;
}

export function ApplicationNavigationHeader({
  slug,
}: ApplicationNavigationHeaderProps) {
  const handleError = useApiErrorHandler();
  const [{ data, error }] = useApplicationHeaderQuery({
    variables: { slug },
  });

  useEffect(() => {
    if (!error) return;
    handleError(error);
  }, [handleError, error]);

  return (
    <NavigationHeader>
      <ApplicationHeader data-recording-sensitive>
        {data?.application?.title}
        {!!data?.application?.workspace?.name && (
          <WorkspaceTitle>{data?.application?.workspace?.name}</WorkspaceTitle>
        )}
      </ApplicationHeader>
    </NavigationHeader>
  );
}

const ApplicationHeader = styled.span`
  display: flex;
  flex-direction: row;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const WorkspaceTitle = styled.span`
  ::before {
    content: " - ";
    white-space: pre;
  }

  @media ${device.tablet} {
    ::before {
      content: none;
    }
  }
`;
