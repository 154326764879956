import React from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";

interface WorkspaceLogoProps {
  logo: string | null;
}

export function WorkspaceLogo({ logo }: WorkspaceLogoProps) {
  return (
    <WorkspaceLogoContainer url={logo}>
      {!logo && "No logo"}
    </WorkspaceLogoContainer>
  );
}

const WorkspaceLogoContainer = styled.div<{ url: string | null }>`
  grid-area: logo;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  font-weight: 500;
  color: ${(props) => props.theme.typography.colorPrimary};

  background-image: ${(props) => props.url && `url(${props.url})`};
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  @media ${device.tablet} {
    display: none;
  }
`;
