import React, { useMemo } from "react";
import { useRouter } from "next/router";
import { isAfter } from "date-fns";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";
import { DSButton } from "@hundred5/design-system";
import { ApplicationState, TestState } from "@/generated/graphql";
import { TestActionButton } from "@/features/test";
import {
  TApplicationListItem,
  useApplicationMissingCandidateDetails,
} from "@/features/application";
import {
  ApplicationTests,
  ApplicationTitle,
  Information,
  MissingDetails,
  WorkspaceLogo,
} from "./ui";

interface ApplicationListItemProps {
  /**
   * Application list item
   */
  application: TApplicationListItem;
}

export function ApplicationListItem({ application }: ApplicationListItemProps) {
  const router = useRouter();

  const unfinishedTest = useMemo(
    () =>
      application.tests?.find((test) =>
        [TestState.Invited, TestState.Started].includes(test.state)
      ),
    [application.tests]
  );

  const latestTest = useMemo(() => {
    if (!!application.tests?.length) {
      return application.tests?.reduce((latest, current) =>
        current.stateTimeStamp &&
        latest.stateTimeStamp &&
        isAfter(
          new Date(current.stateTimeStamp),
          new Date(latest.stateTimeStamp)
        )
          ? current
          : latest
      );
    }
  }, [application.tests]);

  const missingMandatoryDetails = useApplicationMissingCandidateDetails(
    application.state,
    application.customFields,
    application.contactInfo
  );

  return (
    <ApplicationListItemContainer>
      <Content onClick={() => router.push(`/application/${application.slug}`)}>
        <WorkspaceLogo logo={application.workspace.logo} />
        <ApplicationTitle
          jobTitle={application.title}
          workspaceTitle={application.workspace.name}
        />
        <Information
          applicationState={application.state}
          applicationStateTimestamp={application.stateTimestamp}
          latestTest={latestTest}
        />
        <Divider />
        <ApplicationTests tests={application.tests} />
        <Actions>
          {unfinishedTest &&
          (application.state === ApplicationState.Open ||
            application.state === ApplicationState.Invited) ? (
            <TestActionButton
              testState={unfinishedTest.state}
              testType={unfinishedTest.type}
              testSlug={unfinishedTest.slug}
              applicationSlug={application.slug}
            />
          ) : (
            <ViewButton
              variant="secondary"
              onClick={(e) => {
                e.stopPropagation();
                router.push(`/application/${application.slug}`);
              }}
            >
              View
            </ViewButton>
          )}
        </Actions>
      </Content>

      {missingMandatoryDetails && <MissingDetails slug={application.slug} />}
    </ApplicationListItemContainer>
  );
}

const ApplicationListItemContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.shadow.card};
  border-radius: 8px;
  overflow: hidden;

  &:hover {
    background-color: ${(props) => props.theme.colors.purple[5]};
  }
`;

const Content = styled.div`
  cursor: pointer;
  padding: 16px;
  height: 104px;
  display: grid;
  grid-template-areas: "logo title divider tests actions" "logo info divider tests actions";
  grid-auto-columns: min-content 33% min-content minmax(0, 1fr) min-content;
  grid-column-gap: 16px;
  grid-row-gap: 8px;
  align-items: center;

  @media ${device.tablet} {
    grid-template-areas: "title" "info" "actions" "divider" "tests";
    grid-auto-columns: minmax(0, 1fr);
    height: auto;
  }
`;

const Divider = styled.div`
  grid-area: divider;
  border-left: 1px solid ${(props) => props.theme.colors.purple[10]};
  height: 100%;

  @media ${device.tablet} {
    border-bottom: 1px solid ${(props) => props.theme.colors.purple[10]};
    height: 1px;
  }
`;

const Actions = styled.div`
  grid-area: actions;
`;

const ViewButton = styled(DSButton)`
  @media ${device.tablet} {
    display: none;
  }
`;
